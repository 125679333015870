<template>
  <b-card>
    <d-collapse-section
      title="views.playground.index.parameters.options.title"
      :model="collapse"
      accordion="options"
      @on:open-or-close="collapse = ! collapse"
    >
      <div slot="body">
        <div class="mt-2 wrapper">
          <b-row>
            <b-col :cols="cols">
              <option-list
                :reload="reload"
                @on:option:edit="editOption"
                @reset:option-form="resetOptionModel"
              />
            </b-col>
            <div v-if="cols === '5'" class="split-layout__divider">
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col>
              <form-details
                :key="'form-option' + componentFormKey"
                :option="option"
                @on:option-added="reload = !reload"
                @reset:option-form="resetOptionModel"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </d-collapse-section>
  </b-card>
</template>
<script>
import {getOption} from "@api/doinsport/services/option/option.api";
import Option from "@/classes/doinsport/option";

export default {
  data: () => ({
    collapse: false,
    reload: false,
    componentFormKey: 1,
    option: new Option()
  }),
  components: {
    OptionList: () => import('./List'),
    FormDetails: () => import('./Details')
  },
  computed: {
    cols() {
      return this.$store.getters["layout/getInnerWidth"] <= 991 ? '12' : '5';
    }
  },
  methods: {
    editOption(id) {
      getOption(id).then((response) => {
        this.option = new Option(response.data, {deserialize: true});
      })
    },
    resetOptionModel() {
      this.componentFormKey += this.componentFormKey;
      this.option = new Option();
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

.card .card-body {
  padding-left: 30px;
}
</style>
