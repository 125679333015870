import { hydrate } from "@/utils/form";

export default class Option {
    id = null;
    name = null;
    price = 0;
    description = null;
    enabled = true;
    label = null;
    photo = null;
    color = '#000000';
    maxQuantity = null;
    minQuantity = null;
    quantifiable = true;
    club = JSON.parse(localStorage.getItem('selectedClub'));

    constructor(object = null, options) {
        if (typeof options !== "undefined") {
            if (options.serialize) {
                this.serialize(object);
            }
            if (options.deserialize) {
                this.deserialize(object);
            }
        }
    }

    serialize (object) {
        hydrate(this, object);
        this.castToCurrency(object);
        this.castToInt(object);
    }
    deserialize (object) {
        hydrate(this, object);
        this.castToCent(object);
        this.castToString(object);
    }

    castToCurrency(object) {
        this.price = parseFloat(object.price) * 100;
    }
    castToInt(object) {
        if (object.minQuantity) {
            this.minQuantity = parseInt(object.minQuantity);
        }
        if (object.maxQuantity) {
            this.maxQuantity = parseInt(object.maxQuantity);
        }
    }
    castToCent(object) {
        this.price = new String(parseFloat(object.price) / 100).toString();
    }
    castToString(object) {
        if (object.minQuantity) {
            this.minQuantity = new String(object.minQuantity).toString();
        }
        if (object.maxQuantity) {
            this.maxQuantity = new String(object.maxQuantity).toString();
        }
    }
}
